import { Box, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';

function Contact() {
  return (
    <Box my={10} shadow="lg" p={5} bg="blackAlpha.100" id="kontak">
      <Stack direction="row" alignItems="center" spacing={5}>
        <Image
          borderRadius="full"
          boxSize="75px"
          width="95px"
          src="/images/pioda.jpg"
          alt="Pioda School"
        />
        <Text fontSize="lg">Pioda School</Text>
      </Stack>

      <Box mt={5} fontSize="sm">
        <Text>Jl. Perintis Kemerdekaan</Text>
        {/* <Text>Kec. Padang Timur</Text> */}
        <Text>Padang, Sumatera Barat</Text>
        <Text fontWeight="medium" mt={3}>
          Telp. 082390666669
        </Text>
        <Text fontWeight="medium">Whatsapp. 082390666669</Text>
      </Box>
    </Box>
  );
}

export default Contact;
