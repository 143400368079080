import axios from 'axios';

// export const apiUrl = 'https://api.pertiwi.sch.id';
export const apiUrl = 'https://piodaschoolapi.pioda.id';
// export const apiUrl = 'http://localhost:8000';

const instance = axios.create({
  baseURL: apiUrl,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

instance.defaults.withCredentials = true;

export default instance;
