import { Image } from '@chakra-ui/react';

import classes from './logo.module.css';

function Logo(props) {
  return (
    <Image
      className={classes.logo}
      src="/images/pioda.jpg"
      alt="Logo"
      height={[props.heightMobile, null, null, props.height]}
      width={props.width}
    />
  );
}

export default Logo;
