import { useContext, useState } from 'react';
import {
  Flex,
  Heading,
  Text,
  Avatar,
  Popover,
  Stack,
  Center,
} from '@chakra-ui/react';
import {
  FaHome,
  FaUserFriends,
  FaUsers,
  FaQuestion,
  FaSchool,
} from 'react-icons/fa';
import { MdAccountCircle, MdHttps } from 'react-icons/md';

import SidebarItem from './sidebar-item';
// import PisPopover from '../../ui/popover/pis-popover';
import AuthContext from '../../../store/auth-context';
import Logo from '../../ui/logo/logo';

function Sidebar() {
  // const [user, setUser] = useState('');
  // const [isBtnLoading, setIsBtnLoading] = useState(false);

  const authCtx = useContext(AuthContext);

  // const logoutHandler = () => {
  //   setIsBtnLoading(true);
  //   authCtx.logout();
  // };

  return (
    <Flex
      // w={[null, null, null, '10%', '20%']}
      // mr={3}
      bg="#ffffff"
      pr={1}
      fontSize="sm"
      direction="column"
      justify="space-between"
      display={['none', 'none', 'none', 'flex']}
    >
      <Stack spacing={10} pt={10}>
        <Center>
          <Logo height="70px" width="90px" />
        </Center>
        {/* <Heading
          pt={10}
          fontWeight="bold"
          as="div"
          size="sm"
          textTransform="uppercase"
          letterSpacing="wide"
          pl={5}
        >
          Yayasan Pertiwi
        </Heading> */}

        <Flex direction="column" align="flex-start">
          <SidebarItem
            link="/user/dashboard"
            text="Dashboard"
            icon={<FaHome />}
          />

          {!authCtx.isAdmin && (
            <SidebarItem
              link="/user/spp-cek"
              text="SPP"
              icon={<MdAccountCircle />}
            />
          )}

          {authCtx.isAdmin && (
            <>
              <SidebarItem
                subMenu={[
                  {
                    link: '/admin/ppdb',
                    text: 'Pendaftar Baru',
                    icon: FaUsers,
                  },
                  {
                    link: '/admin/unit',
                    text: 'Unit',
                    icon: FaSchool,
                  },
                  {
                    link: '/admin/faq',
                    text: 'FAQ',
                    icon: FaQuestion,
                  },
                  {
                    link: '/admin/carousel',
                    text: 'Slide Carousel',
                    icon: FaQuestion,
                  },
                ]}
                text="PPDB"
                type="menu"
                icon={<FaUserFriends />}
              />
              <SidebarItem
                link="/admin/wali"
                text="Daftar Wali"
                icon={<FaUserFriends />}
              />
              <SidebarItem
                link="/admin/murid"
                text="Daftar Siswa"
                icon={<FaUserFriends />}
              />

              {/* <SidebarItem
                link="/admin/informasi"
                text="Informasi"
                icon={<FaUserFriends />}
              /> */}
              <SidebarItem
                subMenu={[
                  // {
                  //   link: '/admin/keuangan/kategori',
                  //   text: 'Kategori',
                  //   icon: MdOutlineCategory,
                  // },
                  // {
                  //   link: '/admin/keuangan/transaksi',
                  //   text: 'Transaksi',
                  //   icon: MdAccountCircle,
                  // },
                  {
                    link: '/admin/keuangan/spp',
                    text: 'Aktifasi',
                    icon: MdAccountCircle,
                  },
                  {
                    link: '/admin/keuangan/transaksi/spp',
                    text: 'Pembayaran',
                    icon: MdAccountCircle,
                  },
                  {
                    link: '/admin/keuangan/cek-spp',
                    text: 'Cek SPP',
                    icon: MdAccountCircle,
                  },
                  {
                    link: '/admin/keuangan/report',
                    text: 'Report VA',
                    icon: MdAccountCircle,
                  },
                  // {
                  //   link: '/admin/keuangan/laporan',
                  //   text: 'Laporan',
                  //   icon: MdOutlineInsertChartOutlined,
                  // },
                ]}
                text="SPP"
                type="menu"
                icon={<MdAccountCircle />}
              />
            </>
          )}
          <SidebarItem
            subMenu={[
              {
                link: '/user/setting/profile',
                text: 'Profil',
                icon: MdAccountCircle,
              },
              {
                link: '/user/setting/change-password',
                text: 'Ganti Password',
                icon: MdHttps,
              },
            ]}
            text="Pengaturan"
            type="menu"
            icon={<MdHttps />}
          />
        </Flex>
      </Stack>

      {/* <Popover>
        <Flex direction="column" align="center" mb={10} mt={5}>
          <PisPopover
            header="Logout"
            bodyText="Klik Logout untuk keluar"
            bodyBtn={logoutHandler}
            btnText="Logout"
            btnLoading={isBtnLoading}
          >
            <Avatar
              my={2}
              src=""
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </PisPopover>

          <Text textAlign="center" fontWeight="semibold" color="gray.500">
            {authCtx.nama}
          </Text>
        </Flex>
      </Popover> */}
    </Flex>
  );
}

export default Sidebar;
