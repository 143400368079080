import { Flex, Box, Stack, Button, Text } from '@chakra-ui/react';
import { FaAngleDoubleDown, FaQuestion, FaWhatsapp } from 'react-icons/fa';
import Logo from '../../../ui/logo/logo';

function Hero() {
  const scrollToAnchor = id => {
    const anchor = document.querySelector(id);
    anchor.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Flex
      direction={['column', null, 'row']}
      align="center"
      justify="space-between"
      // mt={8}
    >
      <Stack
        w={['100%', null, null, '55%']}
        spacing={6}
        align={['center', null, null, 'start']}
        textAlign={['center', null, null, 'left']}
        minH="100vh"
      >
        <Text
          as="h1"
          fontSize={['3xl', null, '6xl']}
          fontWeight="semibold"
          lineHeight={['50px', null, '70px']}
        >
          Telah dibuka Penerimaan Siswa Baru TA 2024 - 2025
        </Text>

        <Stack
          alignItems="center"
          direction={['column', null, null, 'row']}
          spacing={5}
        >
          <Button
            colorScheme="orange"
            rightIcon={<FaAngleDoubleDown />}
            size="md"
            w="fit-content"
            shadow="lg"
            p={7}
            onClick={() => scrollToAnchor('#unit-pertiwi')}
          >
            Daftar Sekarang
          </Button>

          <Stack direction="row" spacing={3}>
            <Button
              colorScheme="blue"
              variant="outline"
              rightIcon={<FaQuestion />}
              size="md"
              shadow="lg"
              onClick={() => scrollToAnchor('#faq')}
            >
              F.A.Q
            </Button>
            <Button
              colorScheme="whatsapp"
              variant="outline"
              rightIcon={<FaWhatsapp />}
              size="md"
              shadow="lg"
              onClick={() => scrollToAnchor('#kontak')}
            >
              Kontak
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Box
        w="45%"
        display={['none', null, null, 'flex']}
        justifyContent="center"
        minH="100vh"
      >
        <Logo height="370px" width="100%" />
      </Box>
    </Flex>
  );
}

export default Hero;
