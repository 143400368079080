import { useRef, useState } from 'react';
import { mutate } from 'swr';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { sendData } from '../../../lib/fetching';

function PisModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const inputDateRef = useRef();

  const pushHandler = () => {
    props.onClose();
    window.location.href = `https://api.whatsapp.com/send?phone=${props.data.admin}&text=Assalamu'alaikum, saya wali dari ananda *${props.data.nama}* telah melakukan pendaftaran dengan nomor registrasi *Pioda-${props.data.nomor}*`;
  };

  const submitHandler = e => {
    e.preventDefault();
    const [year, month] = inputDateRef.current.value.split('-');
    const date = new Date(`${year}-${month}-01 07:00:00`).getTime();

    setIsLoading(true);

    sendData(`student/${props.id}?set=non-act`, { date: date / 1000 }, 'PUT')
      .then(res => {
        setIsLoading(false);
        props.onClose();
        // console.log(res);
        mutate('student');
      })
      .catch(e => console.log(e));
  };

  return (
    <Modal
      isOpen={props.isOpen}
      closeOnOverlayClick={false}
      size="xs"
      isCentered
    >
      <ModalOverlay
        bg="none"
        backdropFilter="auto"
        backdropInvert="80%"
        backdropBlur="2px"
      />
      <ModalContent>
        <ModalHeader>{props.title}</ModalHeader>

        <ModalBody>
          {props.id && (
            <form onSubmit={e => submitHandler(e)}>
              <FormControl>
                <FormLabel>Tanggal Non Aktif</FormLabel>
                <Input ref={inputDateRef} type="date" required />
                <Button
                  colorScheme="teal"
                  my={3}
                  type="submit"
                  isLoading={isLoading}
                >
                  non-aktifkan
                </Button>
              </FormControl>
            </form>
          )}
          {props.aktifasiHandler && (
            <Text>{`Pastikan siswa *${props.namaSiswaAkfifasi}* sudah membayar biaya pendaftaran.`}</Text>
          )}
          {props.body || null}
        </ModalBody>

        <ModalFooter>
          {props.action && (
            <Button
              colorScheme="teal"
              mr={3}
              onClick={props.action}
              isLoading={props.btnLoading}
              loadingText="Loading..."
            >
              Hapus
            </Button>
          )}
          {props.openWA && props.title !== 'Gagal' && (
            <Button colorScheme="teal" mr={3} onClick={pushHandler}>
              OK
            </Button>
          )}
          {(!props.openWA || props.title === 'Gagal') && (
            <Button colorScheme="teal" mr={3} onClick={props.onClose}>
              Tutup
            </Button>
          )}
          {props.aktifasiHandler && (
            <Button
              colorScheme="teal"
              mr={3}
              onClick={() => props.aktifasiHandler()}
            >
              Aktifkan
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default PisModal;
