export const openNewTab = url => window.open(url, '_blank').focus();

export const onSendMsgHandler = (wali, nama, uangDaftar, rek) => {
  const panggilan = wali.jenkel === 'lk' ? 'Bapak' : 'Ibu';

  const msg = `Assalamu'alaikum ${panggilan} ${wali.nama}.
    Alhamdulillah data pendaftaran ananda *${nama}* telah kami terima. Untuk tahap selanjutnya silahkan ${panggilan} transfer uang pendaftaran *Rp.${uangDaftar},-* ke rekening
    *Bank Nagari : ${rek}*
    *a/n : Pioda.* Wajib memberikan catatan / keterangan nama anak ketika akan transfer.
    Bukti transfer silahkan ${panggilan} upload ke nomor ini.
    Terima kasih.`;

  const url = `https://api.whatsapp.com/send?phone=62${wali.whatsapp}&text=${msg}`;

  openNewTab(url);
};
